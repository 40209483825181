<template>
  <main class="container">
    <ContentHeader
      v-bind:title="content_header.title"
      v-bind:subTitle="content_header.subTitle"
    />

    <div class="page__body">
      <div class="page__body__header">
        <div class="d-flex justify-content-end align-items-center">
          <button
            type="button"
            v-if="userCanCreateTeam"
            class="
              mx-3
              button
              page__body__header__button
              button--grey
              text--capital
            "
          >
            <span
              class="d-inline-flex text--500 mr-2"
              data-toggle="modal"
              data-target="#createNewQueue"
              @click="clearForm"
              >create new team</span
            >
            <ion-icon name="add-outline" class="text--medium"></ion-icon>
          </button>
        </div>
      </div>

      <table class="customtable w-100">
        <thead>
          <tr>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
              style="width: 20.6%"
            >
              name
            </th>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
              style="width: 16.6%"
            >
              sector
            </th>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
              style="width: 16.6%"
            >
              creation date
            </th>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
              style="width: 16.6%"
            >
              status
            </th>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
              style="width: 12.6%"
            >
              members no.
            </th>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
              style="width: 16.6%"
            >
              action
            </th>
          </tr>
        </thead>
        <tbody v-if="userIsNotAuthorised">
          <tr>
            <td colspan="12">
              <div
                class="
                  d-flex
                  flex-column
                  align-items-center
                  justify-content-center
                  p5
                "
              >
                <img src="@/assets/img/empty.svg" alt="delete" srcset="" />

                <span
                  class="d-block text--danger text--600 text--medium mt-3 mb-5"
                  >You do not have permission to view this dashboard</span
                >
              </div>
            </td>
          </tr>
        </tbody>
        <tbody v-if="isLoading">
          <tr>
            <td colspan="12">
              <div class="d-flex align-items-center justify-content-center p5">
                <div
                  class="spinner-border"
                  style="width: 3rem; height: 3rem"
                  role="status"
                >
                  <span class="sr-only">Loading...</span>
                </div>
                <span
                  class="text--black text--600 text--medium d-inline-flex ml-4"
                  >Please wait</span
                >
              </div>
            </td>
          </tr>
        </tbody>
        <tbody v-else-if="allTeamData.length && !isLoading">
          <tr v-for="queue in allTeamData" v-bind:key="queue.teamKey">
            <td>
              <span class="table__body__text table__text text--capital">{{
                queue.teamName
              }}</span>
            </td>
            <td>
              <span
                class="table__body__text table__text text--capital text-info"
                v-if="queue.sector.toLowerCase() == 'private'"
              >
                {{ queue.sector }}
              </span>
              <span
                class="table__body__text table__text text--capital text-warning"
                v-else
              >
                {{ queue.sector }}
              </span>
            </td>
            <td>
              <span class="table__body__text table__text text--capital">{{
                queue.creationDate | moment
              }}</span>
            </td>
            <td>
              <span 
                class="table__body__text table__text text--capital text-success"
                v-if="queue.status"
              >
                active
              </span>
              <span 
                class="table__body__text table__text text--capital text-danger"
                v-else
              >
                inactive
              </span>
            </td>
            <td>
              <span class="table__body__text table__text text--capital"
                >{{ queue.teamMembers.length }}
              </span>
            </td>
            <td>
              <div class="align-self-center dropdown">
                <button
                  class="
                    button button--grey
                    text--capital text--small
                    d-flex
                    align-items-center
                  "
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  action
                  <span class="dropdown__icon">
                    <ion-icon name="caret-down-outline"></ion-icon>
                  </span>
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <a
                    class="dropdown-item text--capital"
                    v-if="userCanEditTeam"
                    data-toggle="modal"
                    data-target="#editQueue"
                    @click="editForm(queue)"
                    >edit</a
                  >
                  <a
                    class="dropdown-item text--capital"
                    v-if="userCanUpdateTeamStatus"
                    data-toggle="modal"
                    data-target="#switchActivationModal"
                    @click="
                      setSwitchActivationParams(queue.teamKey, queue.status)
                    "
                  >update status</a>
                  <a
                    class="dropdown-item text--capital"
                    v-if="userCanDeleteTeam"
                    data-toggle="modal"
                    data-target="#deleteModal"
                    @click="setDeleteQueueId(queue.teamKey)"
                    >delete</a
                  >
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="pagination" v-if="allTeamData.length && !isLoading">
        <div class="d-flex align-items-center justify-content-between w-100">
          <div class="text--capital text--regular pagination__text">
            showing
            <!-- <span
              class="d-inline-block text--primary"
              v-if="paging.totalCount == 0"
              >0</span
            > -->
            <span class="d-inline-block text--primary mr-3">
              <label for="">
                <select
                  v-model.number="size"
                  class="select select--lg select--page"
                  @change="getAllTeams()"
                >
                  <option
                    v-for="psize in pagesizes"
                    :key="psize.id"
                    :id="psize.id"
                    :selected="size"
                  >
                    {{ psize.size }}
                  </option>
                </select>
              </label>
            </span>
            <span class="d-inline-block text--primary">{{
              !paging.hasPrevious
                ? paging.currentPage
                : paging.pageSize * (paging.currentPage - 1) + 1
            }}</span>
            to
            <span class="d-inline-block text--primary">{{
              paging.totalCount
            }}</span>
            <!-- of
            <span>{{ paging.totalCount }}</span> -->
          </div>
          <div
            class="
              pagination__buttons
              d-flex
              align-items-center
              justify-content-between
            "
          >
            <button
              class="button button--sm text--regular"
              v-if="paging.hasPrevious"
              @click="stepPage('previous')"
            >
              <ion-icon name="chevron-back-outline"></ion-icon>
            </button>
            <!-- <button
                class="button text--regular button--sm ml-2 button--primary text--white"
              >
                1
              </button>
              <button class="button text--regular button--sm ml-2">2</button>
              <button class="button text--regular button--sm ml-2">3</button> -->
            <button
              class="button text--regular button--sm ml-2"
              v-for="num in paging.totalPages"
              :key="num"
              :class="{
                'button--primary text--white': paging.currentPage === num,
              }"
              :disabled="paging.currentPage === num"
              @click="changePage(num)"
            >
              {{ num }}
            </button>

            <button
              class="button text--regular button--sm ml-2"
              v-if="paging.hasNext"
              @click="stepPage('next')"
            >
              <ion-icon name="chevron-forward-outline"></ion-icon>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal modal__right"
      id="createNewQueue"
      tabindex=""
      role="dialog"
      aria-labelledby="modalLabel"
      aria-hidden="true"
    >
      <div class="modal__dialog" role="document">
        <div class="modal__content">
          <div class="modal__head">
            <div class="modal__head__icon">
              <a data-dismiss="modal" class="modal__close modal__close--black">
                <ion-icon
                  name="arrow-back-outline"
                  data-dismiss="modal"
                  id="formCloseButton"
                  aria-label="Close"
                  @click="clearForm"
                  :disabled="isSaving"
                ></ion-icon>
              </a>
            </div>
            <h5 class="modal__head__title text--capital">
              Creating New Team Management
            </h5>
          </div>
          <div class="modal__body">
            <form class="form" @submit="saveForm">
              <div class="form__item">
                <div class="form__item mb-0">
                  <label class="form__label">Team Name</label>
                  <input
                    type="text"
                    class="input form__input form__input--lg"
                    placeholder="Enter Team Name"
                    v-model="teamForm.teamName"
                    v-bind:class="{
                      'input-required': teamFormErr.teamName,
                    }"
                  />
                </div>
              </div>

              <div class="form__item">
                <div class="form__item mb-0">
                  <label class="form__label">Team Sector</label>
                  <select class="select select--lg" v-model="teamForm.sector">
                    <option value="" disabled selected></option>
                    <option
                      v-for="stat in queueStatuses"
                      v-bind:key="stat"
                      :value="stat"
                    >
                      {{ stat }}
                    </option>
                  </select>
                </div>
              </div>

              <ul id="accordion" class="mb5 pb5">
                <div class="modal__accordion">
                  <li
                    data-toggle="collapse"
                    class="sidebar-right__item modal__accordion--toggle"
                    data-target="#assignWriter"
                    aria-expanded="false"
                    aria-controls="assignWriter"
                  >
                    <h5
                      class="
                        w-100
                        mb-0
                        d-flex
                        align-items-center
                        justify-content-between
                      "
                    >
                      Team Members
                      <ion-icon name="chevron-down-outline"></ion-icon>
                    </h5>
                  </li>
                  <div
                    id="assignWriter"
                    class="collapse"
                    data-parent="#accordion"
                  >
                    <div class="pt-5">
                      <SearchSelectInput
                        :label="'Select member'"
                        :err="false"
                        v-model="teamForm.selectedUser"
                        @change="addToUserList($event)"
                      ></SearchSelectInput>
                      <div class="form__item">
                        <label class="form__label">Selected Columns</label>
                        <div class="form__item_dottedbg px-5 py-3">
                          <div
                            class="
                              d-flex
                              my-3
                              align-items-center
                              justify-content-between
                            "
                            v-for="user in teamForm.underWriterUserObjects"
                            v-bind:key="user.userKey"
                          >
                            <p
                              class="
                                text--regular text--black
                                form__item_text3
                                text--capital
                              "
                            >
                              {{ user.userFirstName }} &nbsp;
                              {{ user.userLastName }}
                            </p>
                            <button
                              type="button"
                              @click="removeFromUserList(user.userKey)"
                              class="
                                mx-2
                                button button--sm button--cancel
                                text--capital
                              "
                            >
                              <span class="d-inline-flex mr-2">delete</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ul>

              <div class="form__item mt-5">
                <div class="form__action">
                  <button
                    type="submit"
                    class="
                      button
                      form__button form__button--lg
                      w-30
                      save-changes
                    "
                    v-if="!isSaving"
                  >
                    Create this Team
                  </button>
                  <a
                    role="button"
                    class="
                      button
                      form__button form__button--lg
                      w-25
                      save-changes
                    "
                    v-if="isSaving"
                  >
                    <div class="spinner-border text-light" role="status"></div>
                  </a>
                  <a
                    role="button"
                    data-dismiss="modal"
                    class="
                      modal__close
                      form__action__text form__action__text--danger
                      text--capital
                      ml6
                    "
                    @click="clearForm"
                    v-if="!isSaving"
                  >
                    cancel
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal modal__right"
      id="editQueue"
      tabindex=""
      role="dialog"
      aria-labelledby="modalLabel"
      aria-hidden="true"
    >
      <div class="modal__dialog" role="document">
        <div class="modal__content">
          <div class="modal__head">
            <div class="modal__head__icon">
              <a data-dismiss="modal" class="modal__close modal__close--black">
                <ion-icon
                  name="arrow-back-outline"
                  data-dismiss="modal"
                  id="editFormCloseButton"
                  aria-label="Close"
                  @click="clearForm"
                  :disabled="isSaving"
                ></ion-icon>
              </a>
            </div>
            <h5 class="modal__head__title text--capital">Editing Team</h5>
          </div>
          <div class="modal__body">
            <form class="form" @submit="saveEditForm">
              <div class="form__item">
                <div class="form__item mb-0">
                  <label class="form__label">Team Name</label>
                  <input
                    type="text"
                    class="input form__input form__input--lg"
                    placeholder="Enter Name"
                    v-model="teamForm.teamName"
                    v-bind:class="{
                      'input-required': teamFormErr.teamName,
                    }"
                  />
                </div>
              </div>

              <div class="form__item">
                <div class="form__item mb-0">
                  <label class="form__label">Team Sector</label>
                  <select class="select select--lg" v-model="teamForm.sector">
                    <option value="" disabled selected></option>
                    <option
                      v-for="stat in queueStatuses"
                      v-bind:key="stat"
                      :value="stat"
                    >
                      {{ stat }}
                    </option>
                  </select>
                </div>
              </div>

              <ul id="accordion" class="mb5 pb5">
                <div class="modal__accordion">
                  <li
                    data-toggle="collapse"
                    class="sidebar-right__item modal__accordion--toggle"
                    data-target="#assignWriter"
                    aria-expanded="false"
                    aria-controls="assignWriter"
                  >
                    <h5
                      class="
                        w-100
                        mb-0
                        d-flex
                        align-items-center
                        justify-content-between
                      "
                    >
                      Team Members
                      <ion-icon name="chevron-down-outline"></ion-icon>
                    </h5>
                  </li>
                  <div
                    id="assignWriter"
                    class="collapse"
                    data-parent="#accordion"
                  >
                    <div class="pt-5">
                      <SearchSelectInput
                        :label="'Select member'"
                        :err="false"
                        v-model="teamForm.selectedUser"
                        @change="addToUserList($event)"
                      ></SearchSelectInput>
                      <div class="form__item">
                        <label class="form__label">Selected Columns</label>
                        <div class="form__item_dottedbg px-5 py-3">
                          <div
                            class="
                              d-flex
                              my-3
                              align-items-center
                              justify-content-between
                            "
                            v-for="user in teamForm.underWriterUserObjects"
                            v-bind:key="user.userKey"
                          >
                            <p
                              class="
                                text--regular text--black
                                form__item_text3
                                text--capital
                              "
                            >
                              {{ user.userFirstName }} &nbsp;
                              {{ user.userLastName }}
                            </p>
                            <button
                              type="button"
                              @click="removeFromUserList(user.userKey)"
                              class="
                                mx-2
                                button button--sm button--cancel
                                text--capital
                              "
                            >
                              <span class="d-inline-flex mr-2">delete</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ul>
              <div class="form__item mt-5">
                <div class="form__action">
                  <button
                    type="submit"
                    class="
                      button
                      form__button form__button--lg
                      w-30
                      save-changes
                    "
                    v-if="!isSaving"
                  >
                    Update this Team
                  </button>
                  <a
                    role="button"
                    class="
                      button
                      form__button form__button--lg
                      w-25
                      save-changes
                    "
                    v-if="isSaving"
                  >
                    <div class="spinner-border text-light" role="status"></div>
                  </a>
                  <a
                    role="button"
                    data-dismiss="modal"
                    class="
                      modal__close
                      form__action__text form__action__text--danger
                      text--capital
                      ml6
                    "
                    @click="clearForm"
                    v-if="!isSaving"
                  >
                    cancel
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal modal__confirm"
      id="switchActivationModal"
      tabindex=""
      role="dialog"
      aria-labelledby="modalLabel"
      aria-hidden="true"
    >
      <div class="w-100 h-100 d-flex">
        <div class="modal__dialog" role="document">
          <div class="modal__header mb-3">
            <div class="d-flex align-items-center justify-content-between">
              <span class="modal__header__title text--capital">
                confirmation
              </span>
              <a
                data-dismiss="modal"
                class="
                  modal__close modal__close--white
                  d-flex
                  align-items-center
                  justify-content-between
                "
              >
                <span class="text--capital text--regular"> close </span>
                <ion-icon name="close-outline"></ion-icon>
              </a>
            </div>
            <span class="modal__header__subtitle text--capital">
              Team Status Update
            </span>
          </div>
          <div class="modal__content">
            <div class="modal__body">
              <h5 class="modal__head__title text--capital mb-3">
                Are you sure you want to update this team status
              </h5>
              <p class="form__label"></p>
              <div class="d-flex mt-5">
                <button
                  id="activationCloseButton"
                  data-dismiss="modal"
                  class="
                    button button--lg
                    modal__confirm__button modal__confirm__button--cancel
                    mr-2
                  "
                >
                  cancel
                </button>
                <a
                  role="button"
                  class="button form__button form__button--lg w-25 save-changes"
                  v-if="isSaving"
                >
                  <div class="spinner-border text-light" role="status"></div>
                </a>
                <button
                  v-if="!isSaving"
                  @click="switchActivationFunc()"
                  class="
                    button
                    form__button form__button--lg
                    w-30
                    save-changes
                  "
                >
                  update status
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal modal__confirm"
      id="deleteModal"
      tabindex=""
      role="dialog"
      aria-labelledby="modalLabel"
      aria-hidden="true"
    >
      <div class="w-100 h-100 d-flex">
        <div class="modal__dialog" role="document">
          <div class="modal__header mb-3">
            <div class="d-flex align-items-center justify-content-between">
              <span class="modal__header__title text--capital">
                confirmation
              </span>
              <a
                data-dismiss="modal"
                class="
                  modal__close modal__close--white
                  d-flex
                  align-items-center
                  justify-content-between
                "
              >
                <span class="text--capital text--regular"> close </span>
                <ion-icon name="close-outline"></ion-icon>
              </a>
            </div>
            <span class="modal__header__subtitle text--capital">
              Delete Team
            </span>
          </div>
          <div class="modal__content">
            <div class="modal__body">
              <h5 class="modal__head__title text--capital mb-3">
                Are you sure you want to delete this team
              </h5>
              <p class="form__label"></p>
              <div class="d-flex mt-5">
                <button
                  id="deleteCloseButton"
                  data-dismiss="modal"
                  class="
                    button button--lg
                    modal__confirm__button modal__confirm__button--cancel
                    mr-2
                  "
                >
                  cancel
                </button>
                <a
                  role="button"
                  class="button form__button form__button--lg w-25 save-changes"
                  v-if="isSaving"
                >
                  <div class="spinner-border text-light" role="status"></div>
                </a>
                <button
                  v-if="!isSaving"
                  @click="deleteQueue()"
                  class="
                    button
                    form__button form__button--lg
                    w-30
                    save-changes
                    btn-danger
                  "
                >
                  delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--ALERT NOTIFICATION-->
    <div class="toaster show">
      <div
        class="toaster__container"
        v-if="alert.show"
        v-bind:class="{
          'toaster--success': alert.status === 'success',
          'toaster--error': alert.status === 'fail',
          'toaster--warn': alert.status === 'warn',
        }"
      >
        <div class="text-right">
          <span class="fa fa-times" @click="alert.show = false"></span>
        </div>
        <div>
          <span class="d-block text--small text--600 text--white">{{
            alert.title
          }}</span>
          <span class="d-block text--tiny text--400 text--white">{{
            alert.description
          }}</span>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import Vue from "vue";
import { mapState } from "vuex";
import moment from "moment";
import ApiService from "@/core/services/general.service";
import SearchSelectInput from "@/components/Inputs/_base-search-select-input.vue";
import { Role } from "@/core/services/role.js";
// import Multiselect from "vue-multiselect";
export default Vue.extend({
  name: "underwriting",
  components: {
    SearchSelectInput,
    // Multiselect
    },
  computed: {
    userIsNotAuthorised() {
      return this.errorCode == 409 || !this.userCanViewTeamsDashboard;
    },
    ...mapState({
      user: (state) => state.auth.user,
    }),
    userCanViewTeamsDashboard() {
      const account = this.user.scopes.find(item => item.scopeName === Role.TeamManagement);
      const { permissionsList } = account;
      return permissionsList.CanViewTeam;
    },
    userCanUpdateTeamStatus() {
      const account = this.user.scopes.find(item => item.scopeName === Role.TeamManagement);
      const { permissionsList } = account;
      return permissionsList.CanChangeTeamStatus;
    },
    userCanDeleteTeam() {
      const account = this.user.scopes.find(item => item.scopeName === Role.TeamManagement);
      const { permissionsList } = account;
      return permissionsList.CanDeleteTeam;
    },
    userCanCreateTeam() {
      const account = this.user.scopes.find(item => item.scopeName === Role.TeamManagement);
      const { permissionsList } = account;
      return permissionsList.CanCreateTeam;
    },
    userCanEditTeam() {
      const account = this.user.scopes.find(item => item.scopeName === Role.TeamManagement);
      const { permissionsList } = account;
      return permissionsList.CanEditTeam;
    },
  },
  data: function () {
    return {
      content_header: {
        title: "Teams Management",
        subTitle:
          "Setup your teams here and also assign members to the team.",
      },
      queueStatuses: ["Private", "Public"],
      paging: {
        pageIndex: 0,
        totalPages: 0,
        pageSize: 0,
        totalCount: 0,
        hasPreviousPage: false,
        hasNextPage: false,
      },
      alert: {
        show: false,
        status: "Success",
        title: "Success",
        description: "",
      },
      size: 20,
      pageIndex: 1,
      allUsers: [],
      userFetchModel: {
        pageIndex: 1,
        pageSize: 10,
      },
      isLoading: false,
      isSaving: false,
      toDelete: null,
      errorCode: '',
      errorResponse: '',
      pagesizes: [
        {
          size: 10,
        },
        {
          size: 20,
        },
        {
          size: 50,
        },
      ],
      teamForm: {
        teamName: "",
        sector: "",
        teamMembers: [],
        selectedUser: null,
        underWriterKeys: [],
        underWriterUserObjects: [],
      },
      teamFormErr: {
        teamName: false,
        teamMembers: [],
      },
      allTeamData: [],
      teamItemKey: "",
      statusType: false,
    };
  },
  methods: {
    async getAllTeams() {
      try {
        this.allTeamData = [];
        this.isLoading = true;
        const res = await ApiService.get(
          `Team/get-all-Teams/${this.pageIndex}/${this.size}`
        );
        this.allTeamData = res.data.data
        this.paging = res.data;
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
        const error = e.response.data;
        this.alertError(error);
      }
    },

    editForm(team) {
      this.clearForm();
      this.teamForm = {
        teamKey: team.teamKey,
        teamName: team.teamName,
        sector: team.sector,
        teamMembers: team.teamMembers,
        selectedUser: null,
        underWriterKeys: [],
        underWriterUserObjects: [],
      }

      team.teamMembers.forEach((member) => {
        this.teamForm.underWriterKeys.push(member.memberKey);

        const listName = member.memberName.split(' ');
        const userFirstName = listName[0];
        const userLastName = listName[1];
        const userKey = member.memberKey;

        this.teamForm.underWriterUserObjects.push({ userFirstName, userLastName, userKey });
      });
    },

    saveForm: async function (e) {
      e.preventDefault();
      if (this.teamForm.underWriterKeys.length == 0) {
        this.alertWarning("Please add at least one member to this team");
        return;
      }
      if (this.validateForm()) {
        try {
          this.isSaving = true;
          await ApiService.post("Team/create-Team", this.teamForm);
          this.getAllTeams();
          this.clearForm();
          document.getElementById("formCloseButton").click();
          this.alertSuccess("New team added successfully.");
          this.isSaving = false;
        } catch (e) {
          this.isSaving = false;
          const error = e.response.data.message || e.response.data;
          this.alertError(error);
        }
      } else {
        this.alertWarning("Some form fields are required/have errors.");
      }
    },

    saveEditForm: async function (e) {
      e.preventDefault();
      if (this.teamForm.underWriterKeys.length == 0) {
        this.alertWarning("Please add at least one member to this team");
        return;
      }
      if (this.validateForm()) {
        try {
          this.isSaving = true;
          await ApiService.put("Team/update-Team", this.teamForm)
          this.getAllTeams();
          this.clearForm();
          document.getElementById("editFormCloseButton").click();
          this.alertSuccess("Team updated successfully.");
          this.isSaving = false;
        } catch (e) {
          this.isSaving = false;
          const error = e.response.data.message || e.response.data.data;
          this.alertError(error);
        }
      } else {
        this.alertWarning("Some form fields are required/have errors.");
      }
    },

    validateForm() {
      this.clearFormValidation();
      if (this.teamForm.teamName) {
        return true;
      }
      return false;
    },

    clearForm() {
      this.teamForm = {
        teamName: "",
        sector: "",
        teamMembers: [],
        selectedUser: null,
        underWriterKeys: [],
        underWriterUserObjects: [],
      };
    },

    clearFormValidation() {
      this.teamFormErr.teamName = false;
    },

    setSwitchActivationParams(teamKey, status) {
      this.teamItemKey = teamKey;
      this.statusType = status === true ? false : true;
    },

    switchActivationFunc: async function () {
      try {
        this.isSaving = true;
        await ApiService.put(
          `Team/change-Team-state/${this.teamItemKey}/${this.statusType}`
        )
        this.getAllTeams();
        document.getElementById("activationCloseButton").click();
        this.alertSuccess("Team status updated successfully.");
        this.teamItemKey = "";
        this.isSaving = false;
      } catch (e) {
        this.isSaving = false;
        const error = e.response.data.message || e.response.data.data;
        this.alertError(error);
      }
    },

    setDeleteQueueId(teamKey) {
      this.teamItemKey = teamKey;
    },

    deleteQueue: async function () {
      try {
        this.isSaving = true;
        await ApiService.delete(
          `Team/delete-Team/${this.teamItemKey}`
        )
        this.getAllTeams();
        document.getElementById("deleteCloseButton").click();
        this.alertSuccess("Team deleted successfully.");
        this.teamItemKey = "";
        this.isSaving = false;
      } catch (e) {
        this.isSaving = false;
        const error = e.response.data.message || e.response.data.data;
        this.alertError(error);
      }
    },

    addToUserList(user) {
      const userKey = user.userKey;
      if (!this.teamForm.underWriterKeys.includes(userKey)) {
        this.teamForm.underWriterKeys.push(userKey);
        this.teamForm.underWriterUserObjects.push(user);
        const memberName = `${user.userFirstName} ${user.userLastName}`;
        const memberKey = user.userKey;
        this.teamForm.teamMembers.push({ memberKey, memberName });
      }
    },

    removeFromUserList(userKey) {
      let ind = this.teamForm.underWriterKeys.findIndex((x) => x == userKey);
      this.teamForm.underWriterKeys.splice(ind, 1);

      let ind2 = this.teamForm.underWriterUserObjects.findIndex(
        (x) => x.userKey == userKey
      );
      this.teamForm.underWriterUserObjects.splice(ind2, 1);
      this.teamForm.teamMembers = this.teamForm.teamMembers.filter(key => key.memberKey !== userKey);
    },

    // Alerts
    alertSuccess(description) {
      this.alert.status = "success";
      this.alert.title = "Success";
      this.alert.description = description;
      this.alert.show = true;
      this.alert.array = null;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },

    alertError(description, array = null) {
      this.alert.status = "fail";
      this.alert.title = "Error";
      this.alert.description = description;
      this.alert.show = true;
      this.alert.array = array;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },

    alertWarning(description) {
      this.alert.status = "warn";
      this.alert.title = "Warning";
      this.alert.description = description;
      this.alert.show = true;
      this.alert.array = null;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },

    // Paging
    changePage(pageNum) {
      this.pageIndex = pageNum;
      this.getAllTeams();
    },

    stepPage(direction) {
      if (direction == "next") {
        this.pageIndex = this.paging.currentPage + 1;
      }
      if (direction == "previous") {
        this.pageIndex = this.paging.currentPage - 1;
      }
      this.getAllTeams();
    },

    clearPaging() {
      this.paging = {
        pageIndex: 0,
        totalPages: 0,
        pageSize: 0,
        totalCount: 0,
        hasPreviousPage: false,
        hasNextPage: false,
      };
    },
  },
  async created() {
    this.getAllTeams();
  },
  filters: {
    moment: function (date) {
      return date != null ? moment(date).format("Do-MMM-YYYY") : "";
    },
  },
});
</script>
<style scoped>
.customtable tbody td {
  font-weight: inherit;
  font-size: 1.4rem;
  line-height: 17px;
  color: #1e1f20;
}
.has-checkbox,
.text--lh2 {
  line-height: 2rem;
}
.has-checkbox .checkmark {
  top: 0px;
}
label.banner .checkmark {
  top: 0;
}
.form__item-bulk {
  margin-bottom: 2rem;
}
</style>
